/* Your custom fonts here */

@font-face {
  font-family: 'Centrale Sans';
  src: url("../fonts/CentraleSans/CentraleSansBook.woff2") format("woff2"), url("../fonts/CentraleSans/CentraleSansBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Centrale Sans';
  src: url("../fonts/CentraleSans/CentraleSansBook-Italic.woff2") format("woff2"), url("../fonts/CentraleSans/CentraleSansBook-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Centrale Sans';
  src: url("../fonts/CentraleSans/CentraleSans-XBold.woff2") format("woff2"), url("../fonts/CentraleSans/CentraleSans-XBold.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Centrale Sans';
  src: url("../fonts/CentraleSans/CentraleSansBold.woff2") format("woff2"), url("../fonts/CentraleSans/CentraleSansBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Centrale Sans';
  src: url("../fonts/CentraleSans/CentraleSansBold-Italic.woff2") format("woff2"), url("../fonts/CentraleSans/CentraleSansBold-Italic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
