// File for scss-vars.
// For example: main colors, paddings and so on.

// Device dimensions

$phone: 320px;
$xxs: 374px;
$xs: 479px;
$mobile: 639px;
$sm: 767px;
$md: 1024px;
$wd: 1279px;
$lg: 1439px;
$xxl: 1919px;

// Colors

// $black: #333;
$black: rgba(0, 0, 0, 0.75);
$pear: #cbdb2a;
$madison: #0b2265;
$white: #fff;

// Font size

$basic-font-size: 10px;
$centraleSans: 'Centrale Sans';

//others

$basicTransition: all 0.3s;
