@import "variables";
@import "mixins";

.teaser {
  height: 100vh;
  min-height: 100vh;
  padding-top: 60px;
  padding-bottom: 60px;
  box-sizing: border-box;
  color: $white;
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(../img/content/bg/img_teaser.jpg);
  background-position: center;
  background-size: cover;
  background-color: #000;

  @include respond-to(small_tablet) {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .share {
    margin-left: 40px;
  }

  .btn-scroll {
    align-self: center;
    margin-top: auto;
    padding-top: 40px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);

    @include respond-to(small_tablet) {
      min-height: calc(100vh - 18vh);
      // min-height: initial;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin-top: 110px;
    font-size: 14rem;
    font-weight: 900;
    line-height: 100%;
    text-transform: uppercase;

    @include respond-to(small_tablet) {
      margin-top: 50px;
      font-size: 6.4rem;
    }

    span {
      display: block;
      font-size: 7rem;
      font-weight: normal;
      line-height: 1;
      text-transform: initial;

      @include respond-to(small_tablet) {
        font-size: 3.6rem;
      }
    }
  }
}

.section {
  padding: 50px 0;

  @include respond-to(laptop) {
    padding: 25px 0;
  }

  &--intro {
    display: flex;
    flex-direction: column;
    padding-top: 150px;
    background-image: url(../img/content/bg/img_forest.png);
    background-position: bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;

    @include respond-to(laptop) {
      padding-top: 50px;
    }

    @include respond-to(small_tablet) {
      padding-top: 40px;
    }

    &::after {
      content: '';
      display: block;
      align-self: center;
      width: 1px;
      padding-top: 23.75%;

      @include respond-to(laptop) {
        padding-top: 33.25%;
      }

      @include respond-to(phone) {
        padding-top: 44%;
      }
    }
  }

  &--short {
    height: 400px;
    min-height: 400px;
    margin-top: 50px;
    margin-bottom: 50px;
    box-sizing: border-box;
    color: $white;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;

    @include respond-to(laptop) {
      margin-top: 25px;
      margin-bottom: 25px;
      padding-bottom: 60px;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
    }

    &-one {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(../img/content/bg/img_section_001.jpg);
    }

    &-two {
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(../img/content/bg/img_section_002.jpg);
    }

    &-three {
      margin-top: 0;
      background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(../img/content/bg/img_section_003.jpg);
      background-position: center top;

      @include respond-to(small_tablet) {
        margin-top: 25px;
      }
    }
  }

  &--product {
    padding: 100px 0;
    background-color: $pear;

    @include respond-to(laptop) {
      padding: 60px 0;
    }

    @include respond-to(small_tablet) {
      padding: 25px 0;
      background-color: initial;
    }
  }

  &--footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 600px;
    margin-top: 50px;
    padding-bottom: 20px;
    box-sizing: border-box;
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(../img/content/bg/img_footer.jpg);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;

    @include respond-to(small_tablet) {
      height: 300px;
    }
  }

  &__count {

    &::before {
      counter-increment: num;
      content: counter(num);
      display: inline-block;
      margin-bottom: 20px;
      font-size: 12rem;
      font-weight: 900;
      line-height: 1;
      color: $pear;
    }
  }

  &__quote {
    margin: 0;
    font-size: 2.4rem;
    font-weight: bold;
    font-style: italic;
    line-height: 130%;

    @include respond-to(small_tablet) {
      font-size: 2.1rem;
    }

    &::before {
      content: '';
      display: block;
      flex-shrink: 0;
      width: 76px;
      height: 60px;
      margin-bottom: 20px;
      background-image: url(../img/general/icon/icon_quotes.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.intro {
  display: flex;
  justify-content: space-between;

  @include respond-to(small_tablet) {
    flex-direction: column-reverse;
    align-items: center;
  }

  &__item {

    &:first-child {
      padding-top: 20px;

      @include respond-to(tablet) {
        padding-top: 0;
      }

      @include respond-to(small_tablet) {
        margin-top: 30px;
      }
    }
    
    &:not(:first-child) {
      flex-shrink: 0;
      margin-left: 40px;

      @include respond-to(tablet) {
        margin-left: 30px;
      }

      @include respond-to(small_tablet) {
        margin-left: 0;
      }
    }
  }

  &__img {
    @include borderRadius(300px, 300px);

    @include respond-to(laptop) {
      width: 240px;
      height: 240px;
    }
  }
}

.product {
  display: flex;

  @include respond-to(tablet) {
    flex-wrap: wrap;
  }

  @include respond-to(small_tablet) {
    flex-direction: column;
    flex-wrap: initial;
    align-items: center;
  }

  .btn {
    margin-top: 30px;
  }

  &__item {

    &:not(:first-child) {
      margin-left: 30px;
    }

    &:nth-child(2) {

      @include respond-to(tablet) {
        width: 50%;
        flex-grow: 1;
      }

      @include respond-to(small_tablet) {
        width: 100%;
        margin-top: 40px;
        margin-left: 0;
      }

      .product__desc {

        @include respond-to(tablet) {
          display: none;
        }
      }
    }

    &.product__item--mobile {
      width: 100%;
      margin-top: 40px;
      margin-left: 0;
    }

    &--mobile {
      display: none;

      @include respond-to(tablet) {
        display: block;
      }

      .product__desc {
        margin-top: 0;
      }
    }
  }

  &__img {
    flex-shrink: 0;
    width: 270px;
    height: 500px;

    @include respond-to(small_tablet) {
      display: none;
    }

    &--mobile {
      display: none;
      width: 100%;
      height: auto;

      @include respond-to(small_tablet) {
        display: block;
      }
    }
  }

  &__title {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 130%;

    @include respond-to(phone) {
      font-size: 2rem;
    }
  }

  &__list {
    @include nl();
    margin-top: 30px;

    &-item {
      display: flex;
      align-items: flex-start;

      &:not(:first-child) {
        margin-top: 10px;

        @include respond-to(tablet) {
          margin-top: 15px;
        }
      }

      &::before {
        content: '';
        position: relative;
        top: 3px;
        display: block;
        flex-shrink: 0;
        @include borderRadius(16px, 16px);
        margin-right: 17px;
        background-color: $madison;
      }

      .text {

        @include respond-to(phone) {
          font-size: 1.4rem;
        }
      }

      span {
        display: block;
        font-weight: bold;
      }
    }
  }

  &__desc {
    margin-top: 20px;
  }
}
