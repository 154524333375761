@import "variables";
@import "mixins";

html {
  height: 100%;
  min-height: 100%;
  font-size: $basic-font-size;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
ol,
ul,
input,
button,
textarea {
  padding: 0;
  margin: 0;
}

body {
  max-width: 2560px;
  margin: 0 auto;
  height: 100%;
  font-family: $centraleSans, 'Helvetica', sans-serif;
  color: $black;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;

  &.cover {
    height: 100%;
    object-fit: cover;
  }

  &.contain {
    height: 100%;
    object-fit: contain;
  }
}

button,
input {
  border: none;
  background: none;
  outline: none;
}

.content {
  counter-reset: num;
}

.container {
  width: 870px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  @include respond-to(tablet) {
    padding: 0 50px;
  }

  @include respond-to(small_tablet) {
    padding: 0 20px;
  }
}

.text {
  font-size: 1.6rem;
  line-height: 150%;

  &:not(:first-of-type) {
    margin-top: 30px;

    @include respond-to(tablet) {
      margin-top: 20px;
    }
  }

  &--small {
    font-size: 1.4rem;
  }

  &--italic {
    font-style: italic;
  }

  &-link {
    color: $madison;
    text-decoration: underline;

    &--pear {
      color: $pear;
      text-decoration-color: $pear;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.btn {
  display: inline-block;
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  border: 2px solid transparent; 
  border-radius: 20px;
  box-sizing: border-box;
  font-size: 1.4rem;
  line-height: 40px;
  text-transform: uppercase;
  color: $white;
  background-color: $madison;
  transition: $basicTransition;

  &:hover {
    border-color: $madison;
    color: $madison;
    background-color: transparent;
  }

  &-scroll {

    button {
      display: block;
      width: 60px;
      height: 30px;
      background-image: url(../img/general/icon/icon_arrow_down.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      animation: spin 1.3s infinite linear;
      cursor: pointer;

      @include respond-to(phone) {
        width: 30px;
        height: 15px;
      }
    }
  }

  &-share {
    display: none;
    width: 22px;
    height: 24px;
    background-image: url(../img/general/icon/icon_share.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    @include respond-to(small_tablet) {
      display: block;
    }

    &.is-active {
      background-image: url(../img/general/icon/icon_cross.svg);
    }
  }
}

@keyframes spin {
  0% {
    transform: translate3d(0, -8px, 0);
  }
  50% {
    transform: translate3d(0, 8px, 0);
  }
  100% {
    transform: translate3d(0, -8px, 0);
  }
}
