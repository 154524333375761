@import "variables";
@import "mixins";

.logo {
  display: block;
  flex-shrink: 0;
  width: 258px;
  height: 50px;

  @include respond-to(small_tablet) {
    width: 155px;
    height: 30px;
  }
}

.share {
  display: flex;
  align-items: center;

  @include respond-to(small_tablet) {
    position: fixed;
    left: 0;
    bottom: -100%;
    z-index: 100;
    width: 100%;
    justify-content: center;
    padding: 60px 20px 35px;
    box-sizing: border-box;
    background-color: $pear;
    transition: bottom 0.4s ease-in-out;
  }

  &.is-show {
    bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    width: 50px;
    height: 5px;
    border-radius: 4px;
    background-color: $white;

    @include respond-to(small_tablet) {
      display: block;
    }
  }

  &__item {
    cursor: pointer;
    
    &:not(:first-child) {
      margin-left: 25px;

      @include respond-to(small_tablet) {
        margin-left: 40px;
      }
    }

    &:hover {

      path {
        fill: $pear;
        transition: $basicTransition;

        @include respond-to(small_tablet) {
          fill: $madison;
        }
      }
    }

    path {

      @include respond-to(small_tablet) {
        fill: $madison;
      }
    }
  }
}

